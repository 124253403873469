
















































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import tabbar from "src/components/tabbar";
import { Toast, Dialog } from "vant";
import { UserService, OfflineReportExplanationService, WechatService, RigthsService } from "src/services";
import Axios from "axios";

@Component({
    components:
    {
        tabbar
    }
})
export default class Home extends Vue
{
    /**
     * 公告下标
     * @private
     * @returns number
     */
    protected activeIndex:number = 0;

    /**
     * 消息列表数据
     * @private
     * @returns Array<any>
     */
    protected noticeList: Array<any> =[];

    /**
     * 是否显示消息
     * @private
     * @returns boolean
     */
    protected showNotice: boolean = false;

    /**
     * 是否已激活卡
     * @private
     * @returns boolean
     */
    protected memberDiagnosisBlo: boolean = false;

    /**
     * 未读公告集合
     * @private
     * @returns Array<any>
     */

    private noticeListNotRead: Array<any> = [];

    /**
     * 权益列表
     * @private
     */
    private rigthsList: Array<any> = [];

    /**
     * 首页数据
     * @private
     * @returns any
     */
    protected homeData: any =
    {
        banners:[],
        customerServicePhone: "",
        orgInfo: {
            orgName: "",
            startDate: "",
            endDate: "",
            orgLogo: "",
        },
        packages: []

    };

    /**
     * 获取当前登入机构信息
     * @private
     * @returns void
     */
    private get orgInfo(): any
    {
        return this.$store.getters.orgInfo || {};
    }

    /**
     * 当前位置
     * @private
     * @returns any
     */
    protected get location(): any
    {
        return this.$store.getters.location || {};
    }

    /**
     * 跳转套餐页面
     * @private
     * @returns void
     */
    private async onSelectPage(type: string ): Promise<void>
    {
        
        if(type == '6')
        {
            Axios.get("http://elephantdrapi.withealth.cc:8033/api/Elephantdr/getHomeUrl").then(res=>
            {
                console.log(res)
                location.assign(res.data);
            })
            // let res = await UserService.instance.getHomeUrl();
            
        }
        else
        {
            this.$router.push({name: "package-list", query: {type}});
        }
        
    }

    /**
     * 跳转权益
     * @private
     * @returns void
     */
    private onPower(data: any): any
    {
        if (data.serviceType == 11 || data.serviceType == 10)
        {
            if (data.serviceType == 10 && this.memberDiagnosisBlo) // 此处是跳转小程序，做拦截用
            {
                return;
            }
            this.$router.push({name: "powerReportIntroduce", query:{memberServiceItemId : data.memberServiceItemId }});
        }
        else
        {
            this.$router.push({name: "power", query:{memberServiceItemId : data.memberServiceItemId}});
        }
    }

    /**
     * 获取首页消息数据
     * @private
     * @returns void
     */
    private async getNoticeList(): Promise<void>
    {
        try
        {
           
            let {content: result} = await UserService.instance.getNoticeList();
            if(result.data)
            {
                this.noticeList = result.data;
                this.noticeListNotRead = result.data.filter(res => !res.isRead);

                if(this.noticeListNotRead.length > 0)
                {
                    this.showNotice = true;
                }
            }
        }

        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 获取用户是否已经激活问诊套餐卡
     * @private
     * @returns void
     */
    private async getMemberDiagnosis(): Promise<void>
    {
        try
        {
            let {content: result} = await OfflineReportExplanationService.instance.getMemberDiagnosis(this.userInfo.userId);
            if(result)
            {
                this.memberDiagnosisBlo = result.data;
                if (result.data)
                {
                    setTimeout(() => {
                        this.onVideoReport();
                    },100)
                }
            }
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }
    
    /**
     * 已读公告
     * @private
     * @returns void
     */
    private async readNotice(id: number, index: number): Promise<void>
    {
        try
        {
            await UserService.instance.readNotice(id);
            this.noticeListNotRead.splice(index, 1);
            if(this.noticeListNotRead.length == 0)
            {
                this.showNotice = false;
            }
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

    /**
     * 获取权益列表
     * @private
     * @returns void
     */
    private async getServiceItems(): Promise<void>
    {
        try
        {
            const {content: result} = await RigthsService.instance.getServiceItems();

            if(result.data)
            {
                this.rigthsList = result.data;
                // 获取用户是否已经激活问诊套餐卡
                this.getMemberDiagnosis();
            }
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

    /**
     * 跳转套餐页面
     * @private
     * @returns void
     */
    private onPage(name: string): any
    {
        this.$router.push({name});
    }

    /**
     * 获取首页数据
     * @private
     * @returns void
     */
    private async getHomeInfo(): Promise<void>
    {
        try
        {
            let data =
            {
                cityName: this.location.city,
                longitude: this.location.lng,
                latitude: this.location.lat
            }
            let {content: result} = await UserService.instance.getHomeInfo(data);
            if(result.data)
            {
                this.homeData = result.data;
            }
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

    /**
     * 当路由发生变化的时候操作。
     * @protected
     * @param {any} - to 当前路由实例。
     * @returns {void}
     */
    @Watch("$route", {deep: true, immediate: true})
    protected updateOpen(to: any): void
    {
        let openid = localStorage.getItem("openid")
        if(!this.$route.query.openid && !openid)
        {
               
            this.wechatOauth();
           
        }else if(this.$route.query.openid)
        {
            localStorage.setItem("openid", this.$route.query.openid as string);
        }
       
    }

    /**
     * 微信授权
     * @private
     * @returns void
     */
    private async wechatOauth(): Promise<void>
    {
        let url = location.href;
        
        try
        {
            let {content: result} = await UserService.instance.wechatOauth(url);
            if(result.data)
            {
                location.href = result.data;
            }
        }catch(err)
        {
            Toast(JSON.stringify(err));
        }
        
    }

    /**
     * 健康档案
     * @private
     * @returns void
     */
    private async onHealthy(): Promise<void>
    {
        Dialog.alert({
            message: '更新中',
        }).then(() => {
        // on close
        });
    }

    /**
     * 判断是否数微信环境
     * @private
     * @returns boolean
     */
    private isWechat(): boolean
    {
        let ua = navigator.userAgent.toLowerCase();

        if(ua.indexOf("micromessenger") > -1)
        {
            return true;
        }
        else
        {
            return false;
        }

    }

    /**
     * 跳转小程序封装
     * @protected
     * @returns void
     */
    private wx_launch(info): void
    {
        if (!this.isWechat()) {
            return
        }
        var btn = document.getElementById(info.eleId); //获取元素
        if (!btn)
        {
            return;
        }
        let script = document.createElement("script");// 创建script内容插槽 避免template标签冲突
        script.type = "text/wxtag-template"; // 使用script插槽 必须定义这个type
        script.text = info.content // 自定义的html字符串内容
        let html = `<wx-open-launch-weapp style="width:100%; height:100%;display:block;" username="${info.appid}" path="${info.url}">${script.outerHTML}</wx-open-launch-weapp>`;
        btn.innerHTML = html; // html字符串赋值
        // 点击按钮 正常跳转触发
        btn.addEventListener("launch", function (e) {
            console.log("success");
        });
        // 点击跳转 抛出异常
        btn.addEventListener("error", function (e) {
            console.log("fail", e);
            alert(`跳转异常 - ${JSON.stringify(e)}`)
        });
    }

    /**
     * 初始化jssdk
     * @private
     * @returns Promise<any>
     */
    private async init(): Promise<any>
    {
        if(this.isWechat())
        {
            let url = location.href;
            let {content: result} = await WechatService.instance.getWechatJSSDK(url);
            if(result.data)
            {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: result.data.appId, // 必填，公众号的唯一标识
                    timestamp: result.data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
                    signature: result.data.signature, // 必填，签名
                    jsApiList: ["openLocation"],
                    openTagList: ['wx-open-launch-weapp'] // 必填，需要使用的JS接口列表
                });

                wx.ready(function()
                {
                    
                });

            }
        }
        
    }

    /**
     * dom加载完
     * @protected
     * @returns void
     */
    protected  mounted() {
        this.init();
        // 获取权益列表
        this.getServiceItems();
        // 获取公告
        this.getNoticeList();
        let reportContent =`
            <button class="test-btn">跳转跳转跳转跳转跳转跳</button>
            <style>
                .test-btn{
                    width: 100%;
                    height: 69px;
                    background: transparent;
                    border: none;
                    color:rgba(255,255,255,0)
                }
                .test-btn:focus{outline: 0}
            </style>
            ` 
        // 报告解读
        let reportParams = {
            appid: "gh_5cdb318e153e",
            eleId:"report", // 元素id
            url: "/pages/report/report-list/index", // 跳转小程序的页面路径
            content: reportContent // 自定义的html内容
        };

        this.wx_launch(reportParams);

        // // 健康档案
        // let healthyParams = {
        //     appid: "gh_5cdb318e153e",
        //     eleId:"healthy", // 元素id
        //     url: "/pages/healthyFile/healthyFile-list/index", // 跳转小程序的页面路径
        //     content: reportContent // 自定义的html内容
        // };

        // this.wx_launch(healthyParams);
    }

    /**
     * 视频报告解读
     * @protected
     * @returns void
     */
    private onVideoReport(): void
    {
        let videoReportContent =`
            <button class="test-btn">跳转</button>
            <style>
                .test-btn{
                    width: 100%;
                    height: 69px;
                    background: transparent;
                    border: none;
                    color:rgba(255,255,255,0)
                }
                .test-btn:focus{outline: 0}
            </style>
            ` 
        // 报告解读
        let videoReportParams = {
            appid: "gh_5cdb318e153e",
            eleId:"videoReport", // 元素id
            url: "/pages/index/index", // 跳转小程序的页面路径
            content: videoReportContent // 自定义的html内容
        };

        this.wx_launch(videoReportParams);
    }

    
}
