


























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Toast } from "vant";

/**
 * tabbar
 * @class
 * @version 1.0.0
 */
@Component
export default class Tabbar extends Vue
{
    /**
     * 选择tabba e
     * @private
     * @returns string
     */
    private activeValue: string = "home";

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        if(this.$route.meta.tabbarName)
        {
            this.activeValue = this.$route.meta.tabbarName;
        }
    }

    /**
     * 导航栏变更
     * @private
     * @returns void
     */
    private onTabbarChange(name: string): void
    {
        this.$router.push({name});
    }
}
